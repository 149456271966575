import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import store from './store/store';
import { useCookies } from 'react-cookie';
import { router } from './routes/routes';
import { I18nextProvider } from 'react-i18next';
import i18n, { changeLanguage } from './utils/i18n';
import { ToastContainer } from 'react-toastify'
import OfflinePage from './components/OfflinePage';
import FormLoader from './ui/FormLoader';
import CookieBanner from './components/CookieBanner';

// Компонент для введення пароля
const PasswordPrompt = ({ onPasswordCorrect }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctPassword = 'parrot2024'; // Замініть на потрібний пароль

    if (password === correctPassword) {
      const expirationTime = new Date().getTime() + 60 * 60 * 1000; // 1 година
      localStorage.setItem('authToken', JSON.stringify({ expirationTime }));
      onPasswordCorrect();
    } else {
      setError('Неправильний пароль. Спробуйте ще раз.');
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20%' }}>
      <h2>Введіть пароль для доступу</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Пароль"
          style={{ padding: '10px', fontSize: '16px', margin: '10px 0' }}
        />
        <br />
        <button type="submit" style={{ padding: '10px 20px', fontSize: '16px' }}>
          Увійти
        </button>
      </form>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};
//password close

const App = () => {
  const [cookies] = useCookies(['language']);
  const language = cookies.language || 'en';
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  const [loadingTranslations, setLoadingTranslations] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const loadTranslations = async () => {
      await changeLanguage(language);
      setLoadingTranslations(false); 
    };

    loadTranslations();
  }, [language]);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  
  // useEffect(() => {
  //   const authData = JSON.parse(localStorage.getItem('authToken'));
  //   if (authData && authData.expirationTime > new Date().getTime()) {
  //     setIsAuthenticated(true);
  //   }
  // }, []);

  // const handleAuthentication = () => {
  //   setIsAuthenticated(true);
  // };
  // if (!isAuthenticated) {
  //   return <PasswordPrompt onPasswordCorrect={handleAuthentication} />;
  // }
  //password close

  if (isOffline) {
    return <OfflinePage />;
  }

  if (loadingTranslations) {
    return <FormLoader />; 
  }

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <RouterProvider router={router} />
        <ToastContainer />
        <CookieBanner /> 
      </I18nextProvider>
    </Provider>
  );
}

export default App;
